import { ref, computed } from "@vue/composition-api";
import store from "@/store/index"

const useSomeProjects = () => {

    // base data
    const dialog = ref(false);
    const someProjectEdited = ref(null);
    const someProjects = ref([]);
    const search = ref("");
    const loading = ref(false);

    // computed properties
    const titleDialog = computed(() =>
        someProjectEdited.value ? "Editar Proyecto" : "Agregar Proyecto"
    );
    const subtitleDialog = computed(() =>
        someProjectEdited.value ?
        "Rellene los campos correctamente para modificar los datos del Proyecto" :
        "Rellene los campos correctamente para validar los datos del nuevo Proyecto"
    );

    const closeDialog = () => {
        dialog.value = false;
        someProjectEdited.value = null;
    }

    // methods
    const getSomeProjectsList = async() => {
        const { data, status } = await store.dispatch("fetchSomeProjects")
        if (status != 200) return;
        someProjects.value = data;
    }

    const createSomeProject = async(someProject) => {
        loading.value = true;
        const { data, status } = await store.dispatch("createSomeProject", someProject)
        loading.value = false;
        if (status != 200 && status != 201) return;
        await getSomeProjectsList();
        closeDialog();
    }

    const updateSomeProject = async(someProject) => {
        loading.value = true;
        const { data, status } = await store.dispatch("updateSomeProject", someProject)
        loading.value = false;
        if (status != 200 && status != 201) return;
        await getSomeProjectsList();
        closeDialog();
    }

    const removeSomeProject = async(someProject) => {
        const val = await store.dispatch("generateConfirmDialog", {});
        if (!val) return;
        const { data, status } = await store.dispatch("removeSomeProject", someProject)
        if (status != 200 && status != 201 && status != 204) return;
        await getSomeProjectsList();
    }

    getSomeProjectsList();

    return {
        dialog,
        someProjectEdited,
        someProjects,
        search,
        loading,
        // computed
        titleDialog,
        subtitleDialog,
        // methods
        changeSearch(ev) {
            search.value = ev
        },
        openDialog() {
            dialog.value = true;
        },
        openEdit(someProject) {
            dialog.value = true;
            someProjectEdited.value = {...someProject };
        },
        openRemove: removeSomeProject,
        closeDialog,
        createSomeProject,
        updateSomeProject
    };
}

export default useSomeProjects;
