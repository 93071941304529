<template>
  <v-card>
    <v-data-table
      :headers="headers"
      :search="search"
      :items="someProjects"
      :loading="loading"
      item-key="name"
      class="table-rounded datatable-height"
      fixed-header
      disable-sort
    >
      <!-- icon -->
      <template #[`item.img_some_projects`]="{ item }">
        <div class="img center-sub-items">
          <img draggable="false" :src="loadImage(item.img_some_projects)" :alt="item.title_some_projects" />
        </div>
      </template>
      <!-- name -->
      <template #[`item.text`]="{ item }">
        <div class="d-flex flex-column">
          <span class="d-block font-weight-semibold text--primary text-truncate">{{ item.text }}</span>
          <!-- <small>{{ item.post }}</small> -->
        </div>
      </template>
      <template #[`item.actions`]="{ item }">
        <div class="d-flex">
          <CrudButtons @edit="$emit('edit', item)" @remove="$emit('remove', item)"></CrudButtons>
        </div>
      </template>
    </v-data-table>
  </v-card>
</template>

<script>
import CrudButtons from '@/components/buttons/CrudButtons.vue'
import { loadAPIImage } from '@/utils/images'

export default {
  components: {
    CrudButtons,
  },
  props: {
    someProjects: Array,
    search: String,
    loading: Boolean,
  },
  setup() {
    const statusColor = {
      Activo: 'primary',
      Inactivo: 'error',
    }

    const loadImage = src => {
      return loadAPIImage(src)
    }

    return {
      loadImage,
      headers: [
        { text: 'IMAGEN', value: 'img_some_projects' },
        { text: 'PROYECTO', value: 'title_some_projects' },
        { text: 'CATEGORIA', value: 'category' },
        { text: 'DESCRIPCION', value: 'descripcion' },
        { text: 'URL', value: 'link' },
        { text: '', value: 'actions', align: 'right' },
      ],
      status: {
        1: 'Activo',
        2: 'Inactivo',
        //3: 'Rechazado',
      },
      statusColor,
    }
  },
}
</script>

<style lang="scss" scoped>
.img {
  width: 150px;
  height: 150px;
  padding: 10px;
  margin: 10px;
}

.img img {
  max-width: 100px;
  max-height: 100px;
}

.center-sub-items {
  display: flex;
  justify-content: center;
  align-items: center;
}
</style>
