<template>
  <FormTemplate>
    <template v-slot:form-body>
      <v-form ref="formSomeProject" @submit.prevent="saveForm">
        <v-row>
          <v-col cols="12" md="3">
            <label for="category">Categoría</label>
          </v-col>

          <v-col cols="12" md="9" class="pb-0">
            <v-text-field
              id="category"
              v-model="someProject.category"
              outlined
              dense
              placeholder="Categoría"
              :rules="required"
            ></v-text-field>
          </v-col>

          <v-col cols="12" md="3">
            <label for="title_some_projects">Proyecto</label>
          </v-col>

          <v-col cols="12" md="9" class="pb-0">
            <v-text-field
              id="title_some_projects"
              v-model="someProject.title_some_projects"
              outlined
              dense
              placeholder="Proyecto"
              :rules="required"
            ></v-text-field>
          </v-col>

          <v-col cols="12">
            <label for="descripcion">Descripción</label>
          </v-col>

          <v-col cols="12" class="pb-0">
            <v-textarea
              id="descripcion"
              v-model="someProject.descripcion"
              outlined
              dense
              placeholder="Descripción corta del proyecto"
              rows="6"
              :rules="required"
            ></v-textarea>
          </v-col>

          <v-col cols="12" md="3">
            <label for="urlText">URL</label>
          </v-col>

          <v-col cols="12" md="9" class="pb-0">
            <v-text-field
              id="urlText"
              v-model="someProject.link"
              outlined
              dense
              placeholder="URL a redireccionar"
              :rules="required"
            ></v-text-field>
          </v-col>

          <v-col cols="12" md="3">
            <label for="img_some_projects">Imagen</label>
          </v-col>

          <v-col cols="12" md="9" class="pb-0">
            <v-file-input
              id="img_some_projects"
              v-model="image"
              outlined
              prepend-icon=""
              dense
              placeholder="Elige una imagen"
              @change="onFileChange"
            ></v-file-input>
          </v-col>

          <v-col v-if="url || someProjectEdited" cols="12" class="pb-0">
            <div class="section">
              <img draggable="false" :src="url || loadImage(someProject.img_some_projects)" :alt="someProject.text" />
            </div>
          </v-col>

          <v-col offset-md="3" cols="9">
            <v-btn type="submit" :disabled="loading" :loading="loading" color="primary"> Guardar </v-btn>
            <v-btn type="reset" @click="reset" class="mx-2" outlined> Limpiar </v-btn>
          </v-col>
        </v-row>
      </v-form>
    </template>
  </FormTemplate>
</template>

<script>
import FormTemplate from '@/components/forms/FormTemplate.vue'
import { loadAPIImage } from '@/utils/images'

export default {
  components: {
    FormTemplate,
  },
  props: {
    someProjectEdited: Object,
    loading: Boolean,
  },
  data() {
    return {
      required: [v => !!v || 'Requerido'],
      url: '',
      image: null,
      someProject: this.someProjectEdited
        ? { ...this.someProjectEdited }
        : {
            text: '',
            urlText: '',
            description: '',
          },
    }
  },
  methods: {
    validateForm() {
      return this.$refs.formSomeProject.validate()
    },
    reset() {
      this.$refs.formSomeProject.resetValidation()
    },
    saveForm() {
      if (!this.validateForm()) return
      if (this.someProjectEdited) {
        let DTO = { ...this.someProject }

        if (this.image) {
          DTO = {
            ...DTO,
            file_some_projects: this.image,
          }
        }
        // // console.log('actualizar', DTO)
        this.$emit('beforeUpdate', DTO)
      } else {
        const DTO = { ...this.someProject, file_some_projects: this.image }
        // // console.log('crear', DTO)
        this.$emit('beforeCreate', DTO)
      }
    },
    loadImage(src) {
      return loadAPIImage(src)
    },
    onFileChange(e) {
      if (!e) return
      this.url = URL.createObjectURL(e)
    },
  },
}
</script>

<style lang="scss" scoped>
img {
  max-width: 450px;
  max-height: 450px;
}

.center-sub-items {
  display: flex;
  justify-content: center;
  align-items: center;
}

.section {
  margin: 0 auto;
  max-width: 900px;
}
</style>
