<template>
  <v-card>
    <SomeProjectsHeader @openToAdd="openDialog" @search="changeSearch"></SomeProjectsHeader>
    <DatatableSomeProjects
      :someProjects="someProjects"
      :loading="loading"
      :search="search"
      @edit="openEdit"
      @remove="openRemove"
    ></DatatableSomeProjects>
    <WDialog width="500" :dialog="dialog" :closeDialog="closeDialog" :title="titleDialog" :subtitle="subtitleDialog">
      <template v-slot:form-dialog>
        <FormSomeProjects
          v-if="dialog"
          :loading="loading"
          :someProjectEdited="someProjectEdited"
          @beforeUpdate="updateSomeProject"
          @beforeCreate="createSomeProject"
        ></FormSomeProjects>
      </template>
    </WDialog>
  </v-card>
</template>

<script>
import DatatableSomeProjects from './DatatableSomeProjects.vue'
import FormSomeProjects from '@/components/forms/FormSomeProjects.vue'
import SomeProjectsHeader from './SomeProjectsHeader.vue'
import WDialog from '@/components/dialogs/WDialog.vue'
import useSomeProjects from '@/composables/useSomeProjects'

export default {
  components: {
    DatatableSomeProjects,
    FormSomeProjects,
    SomeProjectsHeader,
    WDialog,
  },
  setup() {
    // Composition API
    const {
      dialog,
      someProjectEdited,
      someProjects,
      search,
      loading,
      // computed
      titleDialog,
      subtitleDialog,
      // methods
      changeSearch,
      openDialog,
      openEdit,
      openRemove,
      closeDialog,
      // crud
      createSomeProject,
      updateSomeProject,
    } = useSomeProjects()

    return {
      dialog,
      someProjectEdited,
      someProjects,
      search,
      loading,
      // computed
      titleDialog,
      subtitleDialog,
      // methods
      changeSearch,
      openDialog,
      openEdit,
      openRemove,
      closeDialog,
      // crud
      createSomeProject,
      updateSomeProject,
    }
  },
}
</script>

<style lang="scss" scoped>
</style>
